const Accordion = {
	MuiAccordion: {
		styleOverrides: {
			root: {
				backgroundColor: 'var(--color-white)',
				borderRadius: 20,
				marginBottom: 'var(--spacing-2)',
				'&:last-of-type': {
					marginBottom: 0,
				},
				'&.Mui-expanded': {
					marginBottom: 'var(--spacing-2)',
				},
			},
		},
	},
	MuiAccordionSummary: {
		styleOverrides: {
			root: {
				padding: 'var(--spacing-3) var(--spacing-2)',
				margin: 0,
				minHeight: 'auto',
				'&.Mui-expanded': {
					minHeight: 'auto',
				},
			},
		},
	},
	MuiAccordionDetails: {
		styleOverrides: {
			root: {
				color: 'var(--color-black)',
				padding: '0 var(--spacing-2) var(--spacing-3)',
			},
		},
	},
}

export default Accordion
