const BHBreadcrumbs = {
	BHBreadcrumbs: {
		defaultProps: {
			activeLinkProps: {
				color: 'grey.main',
			},
		},
	},
}

export default BHBreadcrumbs
