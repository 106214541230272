import globalTheme from '@bluheadless/ui/src/theme/default'

const SvgIcon = {
	MuiSvgIcon: {
		styleOverrides: {
			fontSizeLogo: {
				fontSize: globalTheme.typography.pxToRem(100),
			},
			fontSizeMiddle: {
				fontSize: globalTheme.typography.pxToRem(24),
			},
		},
	},
}

export default SvgIcon
