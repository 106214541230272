import { defaultProps as MenuDefaultProps } from '@bluheadless/ui/src/molecules/menu/menu-props'

const BHHeader = {
	BHHeader: {
		defaultProps: {
			menuProps: {
				...MenuDefaultProps,
				dropDownProps: {
					placement: 'bottom-start',
					style: { width: '100%' },
					popperOptions: {
						strategy: 'fixed',
					},
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, 10],
							},
						},
					],
				},
			},
		},
	},
}
export default BHHeader
