const BHRelatedAttributes = {
	BHRelatedAttributes: {
		defaultProps: {
			imageSize: 34,
			showLabel: true,
		},
	},
}

export default BHRelatedAttributes
