import globalTheme from '@bluheadless/ui/src/theme/default'

const Dialog = {
	MuiDialog: {
		styleOverrides: {
			paper: {
				borderRadius: 30,
				scrollbarWidth: 'none',
			},
			paperWidthSm: {
				[globalTheme.breakpoints.up('md')]: {
					minHeight: 'unset',
				},
			},
			paperFullScreen: {
				borderRadius: 0,
			},
		},
	},
}

export default Dialog
