const BHRelated = {
	BHRelated: {
		defaultProps: {
			showTitle: true,
			skeletonProps: { columnGap: 16 },
			sliderProps: {
				breakpoints: {
					xs: {
						spaceBetween: 16,
					},
				},
				controlsPosition: 'default',
				spaceBetween: 16,
				pagination: false,
			},
		},
	},
}

export default BHRelated
