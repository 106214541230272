const BHTileBase = {
	BHTileBase: {
		defaultProps: {
			wishListProps: {
				position: 'outer',
				variant: 'icon',
			},
			addToCartProps: {
				// position unmanaged to force not rendering without override in the theme
				position: 'hidden',
			},
			title1Props: {
				variant: 'body1',
			},
		},
	},
}

export default BHTileBase
