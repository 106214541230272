import { defaultFontProp } from '@/theme/fonts'

const Typography = {
	MuiTypography: {
		defaultProps: {},
		styleOverrides: {
			root: {
				...defaultFontProp,
			},
		},
	},
}

export default Typography
