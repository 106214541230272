const Dropdown = {
	MuiPopover: {
		styleOverrides: {
			paper: {
				scrollbarWidth: 'none',
			},
		},
	},
}
export default Dropdown
