export const fontSize = 12
export const htmlFontSize = 12

export const normalVariation = (weight) => ({
	'--font-weight-axis': weight,
	fontWeight: 'var(--font-weight-axis)',
	fontVariationSettings: `'wght' var(--font-weight-axis)`,
})

export const defaultFontProp = {
	fontFamily: 'var(--font-montserrat)',
	fontWeight: 'var(--font-weight-axis, 400)',
}

export const fonts = {
	montserratThin: {
		...defaultFontProp,
		...normalVariation(100),
	},
	montserratLight: {
		...defaultFontProp,
		...normalVariation(300),
	},
	montserrat: {
		...defaultFontProp,
	},
	montserratMedium: {
		...defaultFontProp,
		...normalVariation(500),
	},
	montserratSemiBold: {
		...defaultFontProp,
		...normalVariation(600),
	},
	montserratBold: {
		...defaultFontProp,
		...normalVariation(700),
	},
	montserratBlack: {
		...defaultFontProp,
		...normalVariation(900),
	},
}
